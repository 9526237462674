import React, { createContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

// Create Context
export const ProfileContext = createContext();

// Create a provider component
export const ProfileProvider = ({ children }) => {
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("google_id_token");
    if (token) {
      const decodedToken = jwtDecode(token);
      setLastName(decodedToken.family_name);
      setFirstName(decodedToken.given_name);
      setEmail(decodedToken.email);
    }
    setLoading(false); // Token checked, set loading to false
  }, []);

  return (
    <ProfileContext.Provider
      value={{
        lastName,
        setLastName,
        firstName,
        setFirstName,
        email,
        setEmail,
        loading,
        setLoading,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
