import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const DetailPanel = ({ data, handleClose }) => {
  return (
    <Box
      sx={{
        padding: 2,
        border: "1px solid #ccc",
        borderRadius: "4px",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Typography variant="h6" component="h2">
          PDL main
        </Typography>
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {data.pdls.map((pdl, index) => (
          <Card sx={{ minWidth: 275 }} key={index}>
            <CardContent>
              <Typography variant="h6" component="h2">
                {pdl.pdl}
              </Typography>
              <FormControl variant="standard" fullWidth margin="normal">
                <InputLabel htmlFor={`pdl-id-${index}`}>PDL ID</InputLabel>
                <Input id={`pdl-id-${index}`} value={pdl.pdl_id} readOnly />
              </FormControl>

              <FormControl variant="standard" fullWidth margin="normal">
                <InputLabel htmlFor={`pdl-id-${index}`}>Adresse</InputLabel>
                <Input id={`adress-${index}`} value={data.address} readOnly />
              </FormControl>

              <FormControl variant="standard" fullWidth margin="normal">
                <InputLabel htmlFor={`activation-date-${index}`}>
                  Date d'activation
                </InputLabel>
                <Input
                  id={`creation-date-${index}`}
                  value={pdl.creation_date}
                  readOnly
                />
              </FormControl>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default DetailPanel;
