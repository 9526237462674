import React, { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, useTheme } from "@mui/material";

const Logs = ({ data }) => {
  const theme = useTheme();
  return (
    <Card>
      <CardHeader
        sx={{
          backgroundColor: "#40bcc4",
          color: theme.palette.primary.main,
          height: "10px",
        }}
        title="Informations logs de connexion"
        titleTypographyProps={{ variant: "h6", fontSize: "0.8rem" }}
      />
      <CardContent></CardContent>
    </Card>
  );
};

export default Logs;
