import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  FormControl,
  CardHeader,
  Input,
  InputLabel,
  useTheme,
  Chip,
} from "@mui/material";

const Configuration = ({ data }) => {
  const theme = useTheme();
  const [startTime, setStartTime] = useState(null);
  const [timeIntervals, setTimeIntervals] = useState([]);

  useEffect(() => {
    const initializeTimeIntervals = () => {
      let initialTime = new Date();
      initialTime.setHours(0, 0, 0, 0); // Set to midnight

      const intervals =
        data["hourly_adjustment_coefficient_values"]?.map((element) => {
          const start = new Date(initialTime);
          const end = new Date(initialTime);
          end.setMinutes(end.getMinutes() + 15); // Add 15 minutes

          // Update the start time for the next interval
          initialTime = new Date(end);

          return {
            start: start.toTimeString().slice(0, 5),
            end: end.toTimeString().slice(0, 5),
            coefficient: element,
          };
        }) || [];

      setTimeIntervals(intervals);
    };

    if (!startTime) {
      initializeTimeIntervals();
    }
  }, [data, startTime]);

  const getColor = (coefficient) => {
    if (coefficient >= 0.8) {
      return "success";
    } else if (coefficient < 0.8 && coefficient >= 0.6) {
      return "warning";
    }
    return "error";
  };
  return (
    <Card>
      <CardHeader
        sx={{
          backgroundColor: "#40bcc4",
          color: theme.palette.primary.main,
          height: "10px",
        }}
        title="Configuration actuelle"
        titleTypographyProps={{ variant: "h6", fontSize: "0.8rem" }}
      />
      <CardContent>
        <FormControl variant="standard" fullWidth>
          <Card
            sx={{
              backgroundColor: "#f8f4f4",
              marginBottom: 2,
            }}
          >
            <CardContent>
              {/* Header Row */}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <FormControl
                  variant="standard"
                  sx={{ flex: 1, textAlign: "center" }}
                >
                  <Typography variant="subtitle2" gutterBottom>
                    Items features
                  </Typography>
                </FormControl>
                <FormControl
                  variant="standard"
                  sx={{ flex: 1, textAlign: "center" }}
                >
                  <Typography variant="subtitle2" gutterBottom>
                    Valeur
                  </Typography>
                </FormControl>
              </Box>

              {/* Data Rows */}
              {timeIntervals.map((element, index) => (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  key={index}
                >
                  <FormControl
                    variant="standard"
                    sx={{ flex: 1, textAlign: "center" }}
                  >
                    <Typography variant="caption" display="block" gutterBottom>
                      {element.start}
                    </Typography>
                  </FormControl>
                  <FormControl
                    variant="standard"
                    sx={{ flex: 1, textAlign: "center" }}
                  >
                    <Typography variant="caption" display="block" gutterBottom>
                      {element.end}
                    </Typography>
                  </FormControl>
                  <FormControl
                    variant="standard"
                    sx={{ flex: 1, textAlign: "center" }}
                  >
                    <Chip
                      label={element.coefficient}
                      color={getColor(element.coefficient)}
                      sx={{
                        width: "50%",
                        margin: "0 auto",
                        justifyContent: "center",
                      }}
                    />
                  </FormControl>
                </Box>
              ))}
            </CardContent>
          </Card>
        </FormControl>
      </CardContent>
    </Card>
  );
};

export default Configuration;
