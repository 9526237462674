import React, { useState, useEffect, useCallback } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Tooltip,
  Checkbox,
  TextField,
  FormControlLabel,
  Select,
  MenuItem,
  useTheme,
} from "@mui/material";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import {
  getPDLById,
  getPdlsByCustomer,
  updateActivePdl,
} from "../../../services/pdlService";
import GeneralInfos from "./GeneralInfos";
import Enedis from "./Enedis";
import Effacement from "./Effacement";
import AssociatedDevices from "./AssociatedDevices";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ActionAlert from "../../layout/ActionAlert";

const DetailPdl = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Get the ID from the URL
  const theme = useTheme();
  const [activePdl, setActivePdl] = useState(false);
  const [dataByCustomer, setDataByCustomer] = useState([]);
  const [data, setData] = useState({});
  const [pdlDescription, setPdlDescription] = useState("");
  const [pdlId, setPdlId] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setPdlId(id);
  }, [id]);

  // Function to fetch detail pdl data
  const onGetPdlByIdData = useCallback(async (pdl_id) => {
    try {
      const dataByPdl = await getPDLById(pdl_id);
      setData(dataByPdl);
      setActivePdl(dataByPdl["active"]);
      const result = await getPdlsByCustomer(dataByPdl["customer_id"]);
      setDataByCustomer(result);

      // Find the element in dataByCustomer that matches pdl_name_nbdevices from dataByPdl
      const matchingElement = result.find(
        (element) => element.pdl_name_nbdevices === dataByPdl.pdl_name_nbdevices
      );

      // Set the matching element as the default value
      if (matchingElement) {
        setPdlDescription(matchingElement);
      }
    } catch (error) {
      console.error("Failed to fetch PDL data:", error);
    }
  }, []);

  // useEffect hook to fetch data on component mount
  useEffect(() => {
    onGetPdlByIdData(id);
  }, [onGetPdlByIdData, id]);

  const handleChange = (event) => {
    const selectedElement = event.target.value;
    setPdlDescription(selectedElement);
    navigate(`/detailpdl/${selectedElement.pdl_id}`);
    onGetPdlByIdData(selectedElement.pdl_id);
  };

  const OnEnableDisablePdl = useCallback(async () => {
    setOpen(true);
    setActivePdl(!activePdl);
    const result = await updateActivePdl(pdlId, !activePdl);
  });

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Card>
            <CardContent>
              <Grid item xs={12} md={12} mb={4}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  value={
                    Object.keys(data).length !== 0
                      ? data["customer_last_name"]
                      : "No specified Customer"
                  }
                  InputProps={{
                    readOnly: true,
                    sx: {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderWidth: "2px",
                        borderColor: theme.palette.primary.main,
                      },
                      "& .MuiInputBase-input": {
                        color: theme.palette.primary.main,
                      },
                    },
                  }}
                  sx={{ mr: 2, width: "300px" }}
                />
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={pdlDescription || ""}
                  label="PDL Description"
                  onChange={handleChange}
                  sx={{
                    mr: 2,
                    width: "300px",
                    backgroundColor: theme.palette.primary.main,
                    color: "white",
                    ".MuiSelect-icon": {
                      color: "white",
                    },
                  }}
                >
                  {dataByCustomer.map((element, index) => (
                    <MenuItem key={index} value={element}>
                      {element.pdl_name_nbdevices}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box display="flex" alignItems="center">
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    color={theme.palette.primary.main}
                    sx={{ fontWeight: "bold" }}
                  >
                    PDL
                  </Typography>
                  <Tooltip title="This is yet another annotation point.">
                    <FiberManualRecordRoundedIcon
                      sx={{
                        ml: 1,
                        cursor: "pointer",
                        color: theme.palette.primary.main,
                        fontSize: "x-",
                      }}
                    />
                  </Tooltip>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    color={theme.palette.primary.main}
                  >
                    {Object.keys(data).length !== 0
                      ? data["pdl_name"]
                      : "No specified PDL name"}
                  </Typography>
                  <FormControlLabel
                    value="start"
                    control={
                      <Checkbox
                        onClick={OnEnableDisablePdl}
                        checked={activePdl}
                      />
                    }
                    label={activePdl ? "Activé" : "Désactivé"}
                    labelPlacement="start"
                    sx={{ color: theme.palette.primary.main }}
                  />
                </Box>
                <ActionAlert
                  open={open}
                  setOpen={setOpen}
                  message={
                    "Le PDL " +
                    data["pdl_name"] +
                    " a été " +
                    (activePdl ? "activé" : "désactivé!")
                  }
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box sx={{ height: 20 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <GeneralInfos pdl_id={id} data={data} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Enedis data={data} />
          <Effacement data={data} />
        </Grid>
        <Grid item xs={12} md={3}>
          <AssociatedDevices data={data} pdl_id={id} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DetailPdl;
