import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#24395E", // Principal color
    },
    secondary: {
      main: "#E6007E", // Secondary color
    },
    other: {
      main: "#4EBCC3",
    },
  },
});

export default theme;
