import * as React from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

const IsMasterRenderer = (props) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Chip label={props.value ? "Main" : "Child"} size="small" />
    </Stack>
  );
};

export default IsMasterRenderer;
