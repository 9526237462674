import React, { useEffect, useContext, useCallback } from "react";
import { ProfileContext } from "../../contexts/ProfileProvider";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import symLogo from "../../assets/images/sym-logo.png";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { getUser } from "../../services/userService";

const GoogleSignIn = () => {
  const { setLastName, setFirstName, setEmail } = useContext(ProfileContext);
  const navigate = useNavigate();

  useEffect(() => {
    const initializeGsi = () => {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_CLIENT_ID,
        callback: handleCredentialResponse,
      });

      window.google.accounts.id.renderButton(
        document.getElementById("google-signin-button"),
        { theme: "outline", size: "large" }
      );
    };

    if (window.google) {
      initializeGsi();
    } else {
      window.addEventListener("load", () => {
        initializeGsi();
      });
    }
  }, []);

  // Function to fetch user data
  const onGetUserData = useCallback(async () => {
    const token = localStorage.getItem("google_id_token");
    if (token) {
      const response = await getUser(token);
      if (response.status === 200) {
        const firstName = response.data.given_name;
        const lastName = response.data.family_name;
        const email = response.data.login;
        setLastName(lastName);
        setFirstName(firstName);
        setEmail(email);
        navigate("/pdls");
      } else {
        localStorage.removeItem("google_id_token");
        navigate("/error");
      }
    }
  }, []);

  const handleCredentialResponse = (response) => {
    localStorage.setItem("google_id_token", response.credential); // Store token immediately
    onGetUserData();
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage:
            'url("https://files.123freevectors.com/wp-content/original/113361-turquoise-blurred-background-vector.jpg")',
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "left",
        }}
      />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        square
      >
        <Card
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minWidth: 275,
            minHeight: 300,
          }}
        >
          <CardContent>
            <img
              src={symLogo}
              style={{ width: "150px", height: "auto" }}
              alt="Logo"
            />
            <Box sx={{ mt: 1 }}>
              <div
                id="google-signin-button"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              ></div>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default GoogleSignIn;
