class SegmentDropdownFilter {
  init(params) {
    this.filterParams = params;
    this.createGui();
  }

  createGui() {
    this.eGui = document.createElement("div");

    this.eGui.innerHTML = `
      <style>
        .ag-filter-select {
          width: 100%;
          padding: 8px;
          font-size: 16px;
          border-radius: 5px;
          border: 1px solid #ccc;
          background-color: #f9f9f9;
          transition: border-color 0.3s ease;
        }

        .ag-filter-select:focus {
          border-color: #007bff;
          outline: none;
          box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
        }

        .ag-filter-select option {
          padding: 10px;
        }
      </style>
      <select class="ag-filter-select">
          <option value="">(All)</option>
          <option value="C1">C1</option>
          <option value="C2">C2</option>
          <option value="C3">C3</option>
          <option value="C4">C4</option>
          <option value="C5">C5</option>
      </select>
    `;

    this.eGui.querySelector("select").addEventListener("change", (event) => {
      this.filterParams.filterChangedCallback();
    });
  }

  getGui() {
    return this.eGui;
  }

  doesFilterPass(params) {
    const selectedValue = this.eGui.querySelector("select").value;
    return (
      selectedValue === "" ||
      params.data[this.filterParams.colDef.field] === selectedValue
    );
  }

  isFilterActive() {
    return this.eGui.querySelector("select").value !== "";
  }

  getModel() {
    if (!this.isFilterActive()) {
      return null;
    }
    return { value: this.eGui.querySelector("select").value };
  }

  setModel(model) {
    this.eGui.querySelector("select").value = model ? model.value : "";
  }
}

export default SegmentDropdownFilter;
