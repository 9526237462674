import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  FormControl,
  CardHeader,
  Input,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  useTheme,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import ActionAlert from "../../layout/ActionAlert";

const GeneralInfos = ({ pdl_id, data }) => {
  const theme = useTheme();
  const [heatingMode, setHeatingMode] = useState([]);
  const [open, setOpen] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);

  const chauffageMode = ["ELECTRIC", "GAS", "COLLECTIVE", "FUEL", "OTHER"];

  const handleChange = (event) => {
    const { value } = event.target;
    setHeatingMode(typeof value === "string" ? value.split(",") : value);
    setOpenSelect(false);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return `${String(date.getDate()).padStart(2, "0")}/${String(
      date.getMonth() + 1
    ).padStart(2, "0")}/${date.getFullYear()} ${String(
      date.getHours()
    ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`;
  };

  const getValueOrDefault = (value) => {
    return value !== undefined && value !== null ? value : "";
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(pdl_id)
      .then(() => {
        setOpen(true);
        console.log(`Copied to clipboard: ${pdl_id}`);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  return (
    <Card>
      <CardHeader
        sx={{
          backgroundColor: "#f6c64f",
          color: theme.palette.primary.main,
          padding: "8px 16px",
          display: "flex",
          alignItems: "center",
        }}
        title="Informations générales - symphonics"
        titleTypographyProps={{ variant: "h6", fontSize: "0.8rem" }}
        action={
          <>
            <IconButton
              aria-label="email"
              sx={{
                padding: "0",
                alignSelf: "center",
              }}
              onClick={handleCopyToClipboard}
            >
              <AlternateEmailIcon sx={{ fontSize: "1rem" }} />
            </IconButton>
            <ActionAlert
              open={open}
              setOpen={setOpen}
              message={"ID copied to clipboard!"}
            />
          </>
        }
      />
      <CardContent>
        <Typography
          gutterBottom
          variant="h7"
          component="div"
          color={theme.palette.primary.main}
          sx={{ fontWeight: "bold" }}
        >
          Affectation
        </Typography>
        <FormControl variant="standard" fullWidth margin="normal">
          <InputLabel htmlFor="pdl-pools">Pool</InputLabel>
          <Input
            id="pdl-pools"
            value={getValueOrDefault(data?.pool_names)}
            readOnly
          />
        </FormControl>

        <Box display="flex" justifyContent="space-between">
          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="certification-date" shrink>
              Certification
            </InputLabel>
            {data["edc_certification_date"] ? (
              <Input
                type="date"
                id="certification-date"
                value={getValueOrDefault(data["edc_certification_date"])}
                readOnly
              />
            ) : (
              <Input
                id="certification-status"
                value="Site non certifié"
                sx={{ color: "orange" }}
                readOnly
              />
            )}
          </FormControl>

          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="group" shrink>
              Groupe
            </InputLabel>
            <Input
              id="group"
              value={getValueOrDefault(data?.groupe)}
              readOnly
            />
          </FormControl>
        </Box>
        <Box sx={{ mt: 5 }} />
        <Typography
          gutterBottom
          variant="h7"
          component="div"
          color={theme.palette.primary.main}
          sx={{ fontWeight: "bold" }}
        >
          Dernières activités
        </Typography>
        <FormControl variant="standard" fullWidth margin="normal">
          <InputLabel htmlFor="last-ingestion">
            Dernière ingestion R50
          </InputLabel>
          <Input
            type="date"
            id="last-ingestion"
            value="test" // Replace "test" with a real value if needed
            readOnly
          />
        </FormControl>

        <Box display="flex" justifyContent="space-between">
          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="last-contract-update" shrink>
              Dernière modification du contrat
            </InputLabel>
            <Input
              id="last-contract-update"
              value={formatDate(data?.last_update_contract)}
              readOnly
            />
          </FormControl>

          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="last-hphc-update" shrink>
              Dernière modification HPHC
            </InputLabel>
            <Input
              id="last-hphc-update"
              value={formatDate(data?.last_update_hphc)}
              readOnly
            />
          </FormControl>
        </Box>
        <Box sx={{ mt: 5 }} />
        <Typography
          gutterBottom
          variant="h7"
          component="div"
          color={theme.palette.primary.main}
          sx={{ fontWeight: "bold" }}
        >
          Informations logement
        </Typography>

        <Box display="flex" justifyContent="space-between">
          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="heating-mode" shrink>
              Mode de chauffage
            </InputLabel>
            <Select
              labelId="heating-mode-label"
              id="heating-mode"
              multiple
              open={openSelect}
              onClose={() => setOpenSelect(false)}
              onOpen={() => setOpenSelect(true)}
              value={heatingMode}
              onChange={handleChange}
              input={<OutlinedInput notched label="Mode de chauffage" />}
            >
              {chauffageMode.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="area" shrink>
              Superficie (m²)
            </InputLabel>
            <Input id="area" value={getValueOrDefault(data?.area)} readOnly />
          </FormControl>
        </Box>

        <FormControl variant="standard" fullWidth margin="normal">
          <InputLabel htmlFor="address" shrink>
            Adresse
          </InputLabel>
          <Input id="address" value={data["address"]} readOnly />
        </FormControl>

        <Box display="flex" justifyContent="space-between" mt={3}>
          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="nearest-weather-station" shrink>
              Station météorologique la plus proche
            </InputLabel>
            <Input
              id="nearest-weather-station"
              value={getValueOrDefault(data?.nearest_weather_station)}
              readOnly
            />
          </FormControl>

          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="insee-code" shrink>
              Code INSEE
            </InputLabel>
            <Input
              id="insee-code"
              value={getValueOrDefault(data?.address_insee_code)}
              readOnly
            />
          </FormControl>
        </Box>

        <Box display="flex" justifyContent="space-between" mt={3}>
          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="longitude" shrink>
              Longitude
            </InputLabel>
            <Input
              type="number"
              id="longitude"
              value={getValueOrDefault(data?.longitude)}
              readOnly
            />
          </FormControl>

          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="latitude" shrink>
              Latitude
            </InputLabel>
            <Input
              type="number"
              id="latitude"
              value={getValueOrDefault(data?.latitude)}
              readOnly
            />
          </FormControl>

          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="climatic-zone" shrink>
              Zone climatique
            </InputLabel>
            <Input
              id="climatic-zone"
              value={getValueOrDefault(data?.zone)}
              readOnly
            />
          </FormControl>
        </Box>
      </CardContent>
    </Card>
  );
};

export default GeneralInfos;
