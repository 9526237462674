import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  FormControl,
  CardHeader,
  Input,
  InputLabel,
  useTheme,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

const Enedis = ({ data }) => {
  const theme = useTheme();
  const getValueOrDefault = (value) => {
    return value !== undefined && value !== null ? value : "";
  };

  return (
    <Card>
      <CardHeader
        sx={{
          backgroundColor: "#b8c494",
          color: theme.palette.primary.main,
          height: "10px",
        }}
        title="Informations ENEDIS"
        titleTypographyProps={{ variant: "h6", fontSize: "0.8rem" }}
      />
      <CardContent>
        <Typography
          gutterBottom
          variant="h7"
          component="div"
          color={theme.palette.primary.main}
          sx={{ fontWeight: "bold" }}
        >
          Informations contractuelles
        </Typography>
        <Box display="flex" justifyContent="space-between">
          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="enedis-state" shrink>
              Etat Enedis
            </InputLabel>
            <Input
              id="enedis-state"
              value={getValueOrDefault(data?.contractual_state)}
              readOnly
            />
          </FormControl>
          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="enedis-segment" shrink>
              Segment
            </InputLabel>
            <Input
              id="enedis-segment"
              value={getValueOrDefault(data?.enedis_segment)}
              readOnly
            />
          </FormControl>
        </Box>
        <Box sx={{ mt: 5 }} />
        <Box display="flex" justifyContent="space-between">
          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="enedis-counter" shrink>
              Compteur
            </InputLabel>
            <Input
              id="enedis-counter"
              value={getValueOrDefault(data?.counting_type)}
              readOnly
            />
          </FormControl>
          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="enedis-power" shrink>
              Puissance Souscrite (kVa)
            </InputLabel>
            <Input
              id="enedis-power"
              value={getValueOrDefault(data?.max_subscribed_power)}
              readOnly
            />
          </FormControl>
        </Box>
        <Box sx={{ mt: 5 }} />
        <Box display="flex" justifyContent="space-between">
          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="enedis-serial" shrink>
              N° de série
            </InputLabel>
            <Input
              type="number"
              id="enedis-serial"
              value={getValueOrDefault(data?.meter_serial_number)}
              readOnly
            />
          </FormControl>

          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="enedis-injection" shrink>
              Présence d'injection sur les 3 dernières années
            </InputLabel>
            <Input
              id="enedis-injection"
              value={getValueOrDefault(
                data?.injection_power_presence ? "Yes" : "No"
              )}
              readOnly
            />
          </FormControl>
        </Box>
        <Box sx={{ mt: 5 }} />
        <Box display="flex" justifyContent="space-between">
          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="enedis-calendar" shrink>
              Calendrier
            </InputLabel>
            <Input
              id="enedis-calendar"
              value={getValueOrDefault(data?.frn_calendar_label)}
              readOnly
            />
          </FormControl>

          <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
            <InputLabel htmlFor="enedis-option" shrink>
              Option tarifaire
            </InputLabel>
            <Input id="enedis-option" value="" readOnly />
          </FormControl>
        </Box>
        <Box sx={{ mt: 5 }} />
        <FormControl variant="standard" fullWidth>
          <InputLabel htmlFor="enedis-formula" shrink>
            Formule
          </InputLabel>
          <Input
            id="enedis-formula"
            value={getValueOrDefault(data?.routing_tariff_formula_label)}
            readOnly
          />
        </FormControl>
        <Box sx={{ mt: 5 }} />
        <FormControl variant="standard" fullWidth>
          <InputLabel htmlFor="enedis-offpeak-hours" shrink>
            Plage(s) d'heures creuses actuelles
          </InputLabel>
          <List sx={{ padding: 0, marginTop: "20px" }}>
            <ListItem
              key="off_peak_start"
              disableGutters
              sx={{ marginBottom: "2px", padding: "0" }}
            >
              <ListItemText
                primary={`• ${getValueOrDefault(data?.off_peak_start)}`}
                primaryTypographyProps={{
                  fontSize: "0.875rem",
                }}
                sx={{ margin: 0 }}
              />
            </ListItem>
            <ListItem
              key="off_peak_end"
              disableGutters
              sx={{ marginBottom: "2px", padding: "0" }}
            >
              <ListItemText
                primary={`• ${getValueOrDefault(data?.off_peak_end)}`}
                primaryTypographyProps={{
                  fontSize: "0.875rem",
                }}
                sx={{ margin: 0 }}
              />
            </ListItem>
          </List>
        </FormControl>
      </CardContent>
    </Card>
  );
};

export default Enedis;
