import axios from "axios";

const getDevice = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/deviceView`
    );
    const devices = response.data;
    return devices;
  } catch (error) {
    console.error("Error fetching Devices:", error);
    return [];
  }
};

const updateActiveDevice = async (device_id, active) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_API_BASE_URL}/updateStatutDeviceView`,
      { device_id: device_id, active: active }
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error("Error updating Device:", error);
    return "";
  }
};

export { getDevice, updateActiveDevice };
