import axios from "axios";

const getPools = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/poolsView`
    );
    const pools = response.data;
    return pools;
  } catch (error) {
    console.error("Error fetching Devices:", error);
    return [];
  }
};

export { getPools };
