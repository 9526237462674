import React, { useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useTheme } from "@mui/material/styles";

const UploadAlert = ({ open, setOpen }) => {
  const theme = useTheme();
  useEffect(() => {
    setOpen(open);
  }, [open, setOpen]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%", backgroundColor: theme.palette.other.main }}
        >
          Le téléchargement est fini !
        </Alert>
      </Snackbar>
    </div>
  );
};

export default UploadAlert;
