import React, { useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useTheme } from "@mui/material/styles";

const ActionAlert = ({ open, setOpen, message }) => {
  const theme = useTheme();
  useEffect(() => {
    setOpen(open);
  }, [open, setOpen]);

  const handleClose = (event, reason) => {
    setOpen(false);
  };

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%", backgroundColor: theme.palette.other.main }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ActionAlert;
