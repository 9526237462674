import React, { useState, useEffect, useCallback } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Tooltip,
  Checkbox,
  TextField,
  FormControlLabel,
  Select,
  MenuItem,
  useTheme,
} from "@mui/material";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  getPDLById,
  getPdlsByCustomer,
  updateActivePdl,
} from "../../../services/pdlService";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ActionAlert from "../../layout/ActionAlert";
import GeneralInfos from "./GeneralInfos";
import ProviderInfos from "./ProviderInfos";
import Effacement from "./Effacement";
import Configuration from "./Configuration";
import Logs from "./Logs";

const DetailDevice = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Get the ID from the URL
  const theme = useTheme();
  const [activeDevice, setActiveDevice] = useState(false);
  const [dataByCustomer, setDataByCustomer] = useState([]);
  const [data, setData] = useState({});
  const [DeviceDescription, setDeviceDescription] = useState("");
  const [DeviceId, setDeviceId] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setDeviceId(id);
  }, [id]);

  // Function to fetch detail Device data
  const onGetDeviceByIdData = useCallback(async (device_id) => {
    try {
      const dataByDevice = await getPDLById(device_id);
      setData(dataByDevice);
      setActiveDevice(dataByDevice["active"]);
      const result = await getPdlsByCustomer(dataByDevice["customer_id"]);
      setDataByCustomer(result);

      // Find the element in dataByCustomer that matches Device_name_nbdevices from dataByDevice
      const matchingElement = result.find(
        (element) =>
          element.Device_name_nbdevices === dataByDevice.Device_name_nbdevices
      );

      // Set the matching element as the default value
      if (matchingElement) {
        setDeviceDescription(matchingElement);
      }
    } catch (error) {
      console.error("Failed to fetch Device data:", error);
    }
  }, []);

  // useEffect hook to fetch data on component mount
  useEffect(() => {
    onGetDeviceByIdData(id);
  }, [onGetDeviceByIdData, id]);

  const handleChange = (event) => {
    const selectedElement = event.target.value;
    setDeviceDescription(selectedElement);
    navigate(`/detaildevice/${selectedElement.device_id}`);
    onGetDeviceByIdData(selectedElement.device_id);
  };

  const OnEnableDisableDevice = useCallback(async () => {
    setOpen(true);
    setActiveDevice(!activeDevice);
    const result = await updateActivePdl(DeviceId, !activeDevice);
  });

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Card>
            <CardContent>
              <Grid item xs={12} md={12} mb={4}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  value={
                    Object.keys(data).length !== 0
                      ? data["customer_last_name"]
                      : "No specified Customer"
                  }
                  InputProps={{
                    readOnly: true,
                    sx: {
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderWidth: "2px",
                        borderColor: theme.palette.primary.main,
                      },
                      "& .MuiInputBase-input": {
                        color: theme.palette.primary.main,
                      },
                    },
                  }}
                  sx={{ mr: 2, width: "300px" }}
                />
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={DeviceDescription || ""}
                  label="PDL Description"
                  onChange={handleChange}
                  sx={{
                    mr: 2,
                    width: "300px",
                    backgroundColor: theme.palette.primary.main,
                    color: "white",
                    ".MuiSelect-icon": {
                      color: "white",
                    },
                  }}
                >
                  {dataByCustomer.map((element, index) => (
                    <MenuItem key={index} value={element}>
                      {element.Device_name_nbdevices}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box display="flex" alignItems="center">
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    color={theme.palette.primary.main}
                    sx={{ fontWeight: "bold" }}
                  >
                    PDL
                  </Typography>
                  <Tooltip title="This is yet another annotation point.">
                    <FiberManualRecordRoundedIcon
                      sx={{
                        ml: 1,
                        cursor: "pointer",
                        color: theme.palette.primary.main,
                        fontSize: "x-",
                      }}
                    />
                  </Tooltip>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    color={theme.palette.primary.main}
                  >
                    {Object.keys(data).length !== 0
                      ? data["Device_name"]
                      : "No specified PDL name"}
                  </Typography>
                  <Tooltip title="This is yet another annotation point.">
                    <ArrowForwardIosIcon
                      sx={{
                        ml: 1,
                        cursor: "pointer",
                        color: theme.palette.primary.main,
                        fontSize: "x-",
                      }}
                    />
                  </Tooltip>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    color={theme.palette.primary.main}
                    sx={{ fontWeight: "bold" }}
                  >
                    {Object.keys(data).length !== 0
                      ? data["Device_name"]
                      : "No specified PDL name"}
                  </Typography>
                  <FormControlLabel
                    value="start"
                    control={
                      <Checkbox
                        onClick={OnEnableDisableDevice}
                        checked={activeDevice}
                      />
                    }
                    label={activeDevice ? "Activé" : "Désactivé"}
                    labelPlacement="start"
                    sx={{ color: theme.palette.primary.main }}
                  />
                </Box>
                <ActionAlert
                  open={open}
                  setOpen={setOpen}
                  message={
                    "Le PDL " +
                    data["Device_name"] +
                    " a été " +
                    (activeDevice ? "activé" : "désactivé!")
                  }
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box sx={{ height: 20 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <GeneralInfos device_id={id} data={data} />
        </Grid>
        <Grid item xs={12} md={4}>
          <ProviderInfos data={data} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Effacement data={data} />
          <Configuration data={data} />
          <Logs data={data} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DetailDevice;
