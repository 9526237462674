import axios from "axios";

const getCustomer = async (token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/customerView`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const customers = response.data;
    return customers;
  } catch (error) {
    console.error("Error fetching Customers:", error);
    return [];
  }
};

const updateActiveCustomer = async (customer_id, active) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_API_BASE_URL}/updateStatutCustomerView`,
      { customer_id: customer_id, active: active }
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error("Error updating Customer:", error);
    return "";
  }
};

export { getCustomer, updateActiveCustomer };
