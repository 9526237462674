import React from "react";
import CircleIcon from "@mui/icons-material/Circle";

const OnOffRenderer = (props) => {
  return (
    <span
      className="missionSpan"
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        height: "100%",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <CircleIcon
          style={{
            color: props.value ? "green" : "red",
            fontSize: "16px",
          }}
        />
        <span style={{ marginLeft: "8px" }}>{props.value ? "ON" : "OFF"}</span>
      </div>
    </span>
  );
};

export default OnOffRenderer;
