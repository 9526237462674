import React, { useRef, useState, useEffect, useCallback } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Box } from "@mui/material";
import AgGrid from "../common/aggrid/AgGrid";
import { getPDL, updateCellPdl } from "../../services/pdlService";
import { getPools } from "../../services/poolService";
import { createPdlPool, deletePdlPool } from "../../services/pdlPoolService";
import StatusButtonRenderer from "../common/renderers/StatusButtonRenderer";
import CertificationButtonRenderer from "../common/renderers/CertificationButtonRenderer";
import CellActionsRenderer from "../common/renderers/CellActionsRenderer";
import HoverRenderer from "../common/renderers/HoverRenderer";
import PdlNameRenderer from "../common/renderers/PdlNameRenderer";
import SegmentDropdownFilter from "../common/filters/SegmentDropdownFilter";
import OptDropdownFilter from "../common/filters/OptDropdownFilter";
import BooleanDropdownFilter from "../common/filters/BooleanDropdownFilter";
import {
  formatDate,
  dateComparator,
  formatDateTime,
} from "../../utils/dateUtils";

const PDL = () => {
  const gridRef = useRef(null);
  const [rowData, setRowData] = useState([]);
  const [pools, setPools] = useState([]);

  const title = "PDLs";

  // Column definitions for the AgGrid
  const columnDefs = [
    { headerName: "PDL", field: "name", cellRenderer: PdlNameRenderer },
    {
      headerName: "Statut",
      field: "active",
      cellRenderer: StatusButtonRenderer,
      filter: BooleanDropdownFilter,
      filterParams: {
        trueLabel: "Activé",
        falseLabel: "Désactivé",
      },
      valueFormatter: (params) => (params.value ? "Activé" : "Désactivé"),
    },
    {
      headerName: "Date activation",
      field: "creation_date",
      sortable: true,
      filter: "agDateColumnFilter",
      valueFormatter: formatDate,
      filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          return dateComparator(filterLocalDateAtMidnight, cellValue);
        },
      },
    },
    {
      headerName: "Date résiliation",
      field: "resiliation_date",
      sortable: true,
      filter: "agDateColumnFilter",
      valueFormatter: formatDate,
      filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          return dateComparator(filterLocalDateAtMidnight, cellValue);
        },
      },
    },
    {
      headerName: "Certification",
      field: "edc_certification_date",
      filter: true,
      editable: false,
      sortable: false,
      cellRenderer: CertificationButtonRenderer,
      filter: BooleanDropdownFilter,
      filterParams: {
        trueLabel: "Certifié",
        falseLabel: "Non certifié",
      },
      valueFormatter: (params) => (params.value ? "Certifié" : "Non certifié"),
    },
    {
      headerName: "Pool",
      field: "pool_names",
      editable: false,
      sortable: false,
      filter: "agSetColumnFilter",
      filterParams: {
        multiSelect: true,
        buttons: ["apply", "reset"],
      },
      // cellEditor: MultipleSelectEditor,
      // cellEditorParams: {
      //   pools: pools,
      // },
      // valueSetter: (params) => {
      //   if (params.newValue !== params.oldValue) {
      //     console.log("Value Setter Called:", params.newValue);
      //     params.data.pool_names = params.newValue;
      //     return true;
      //   }
      //   return false;
      // },
    },
    {
      headerName: "Clients",
      field: "last_name",
      filter: true,
      sortable: false,
      cellRenderer: HoverRenderer,
      cellRendererParams: (params) => {
        return {
          formattedText:
            params.data && params.data.informations_customer
              ? params.data.informations_customer
                  .replaceAll("-", " ")
                  .split(", ")
                  .join("\n")
              : "",
        };
      },
    },
    {
      headerName: "CP",
      field: "address_postal_code",
      filter: true,
      sortable: false,
    },
    {
      headerName: "Segment",
      field: "enedis_segment",
      sortable: true,
      filter: SegmentDropdownFilter,
    },
    {
      headerName: "Opt. tarifaire",
      field: "pricing_option",
      filter: true,
      sortable: false,
      filter: OptDropdownFilter,
    },
    {
      headerName: "Max PowerReal",
      field: "total_max_power_real",
      sortable: true,
      filter: false,
    },
    {
      headerName: "Nbre d'appareils",
      field: "nbDevices",
      sortable: true,
      filter: false,
      cellRenderer: HoverRenderer,
      cellRendererParams: (params) => {
        return {
          formattedText:
            params.data && params.data.device_descriptions
              ? params.data.device_descriptions.split(", ").join("\n")
              : "",
        };
      },
    },
    {
      headerName: "Dern. ingest. R50",
      field: "last_cdc_ingestion_date",
      filter: true,
      sortable: false,
      filter: "agDateColumnFilter",
      valueFormatter: formatDateTime,
      filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          return dateComparator(filterLocalDateAtMidnight, cellValue);
        },
      },
    },
    {
      headerName: "",
      field: "actions",
      cellRenderer: CellActionsRenderer,
      cellClass: "sticky-action-column",
      width: 100,
      pinned: "right",
      cellRendererParams: {
        options: ["Consulter le détail"],
        url: "detailpdl",
        onUpdateRowData: (id, newStatut) => {
          setRowData((prevData) =>
            prevData.map((row) =>
              row.id === id ? { ...row, active: newStatut } : row
            )
          );
        },
      },
    },
  ];

  // Fetch pool data
  const fetchPools = useCallback(async () => {
    try {
      const data = await getPools();
      setPools(data);
    } catch (error) {
      console.error("Failed to fetch pools data:", error);
    }
  }, []);

  // Fetch PDL data
  const fetchPdlData = useCallback(async () => {
    try {
      let data = await getPDL();
      const formattedData = data.map((pdl) => {
        return {
          ...pdl,
          creation_date: pdl.creation_date ? new Date(pdl.creation_date) : null,
          resiliation_date: pdl.resiliation_date
            ? new Date(pdl.resiliation_date)
            : null,
          last_cdc_ingestion_date: pdl.last_cdc_ingestion_date
            ? new Date(pdl.last_cdc_ingestion_date)
            : null,
        };
      });
      setRowData(formattedData);
    } catch (error) {
      console.error("Failed to fetch Pdl data:", error);
    }
  }, []);

  useEffect(() => {
    fetchPdlData();
  }, [fetchPdlData]);

  useEffect(() => {
    fetchPools();
  }, [fetchPools]);

  const handleCellValueChange = async (params) => {
    if (params.colDef.field === "pool_names") {
      const poolList = params.newValue ? params.newValue.split(", ") : [];
      await updatePdlPools(poolList, params);
    } else {
      const updatedData = {
        pdl_id: params.data.id,
        field_name: params.colDef.field,
        new_value: params.newValue,
      };
      await updateCellPdl(updatedData);
    }
  };

  const updatePdlPools = async (poolList, params) => {
    await Promise.all([
      createNewPdlPools(poolList, params),
      deleteOldPdlPools(poolList, params),
    ]);
  };

  const createNewPdlPools = async (poolList, params) => {
    const oldValues = params.oldValue ? params.oldValue.split(", ") : [];
    const newPools = poolList.filter((pool) => !oldValues.includes(pool));

    await Promise.all(
      newPools.map(async (poolName) => {
        const pool = pools.find((p) => p.name === poolName);
        if (pool) {
          await createPdlPool({ pdl_id: params.data.id, pool_id: pool.id });
        }
      })
    );
  };

  const deleteOldPdlPools = async (poolList, params) => {
    const oldValues = params.oldValue ? params.oldValue.split(", ") : [];
    const poolsToDelete = oldValues.filter((pool) => !poolList.includes(pool));

    await Promise.all(
      poolsToDelete.map(async (poolName) => {
        const pool = pools.find((p) => p.name === poolName);
        if (pool) {
          await deletePdlPool({ pdl_id: params.data.id, pool_id: pool.id });
        }
      })
    );
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <AgGrid
        gridRef={gridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        title={title}
        search={"Rechercher un PDL, un client ou un code postal"}
        onCellValueChange={handleCellValueChange}
        filterColumns={["name", "address_postal_code", "last_name"]}
      />
    </Box>
  );
};

export default PDL;
