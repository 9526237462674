import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  FormControl,
  CardHeader,
  InputLabel,
  useTheme,
} from "@mui/material";
import { getDevicesByPdlId } from "../../../services/pdlService";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import CircleIcon from "@mui/icons-material/Circle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import StopCircleIcon from "@mui/icons-material/StopCircle";

const AssociatedDevices = ({ data, pdl_id }) => {
  const theme = useTheme();
  const [devices, setDevices] = useState([]);

  const onGetDevicesbyPdlId = useCallback(async () => {
    try {
      const data = await getDevicesByPdlId(pdl_id);
      setDevices(data);
    } catch (error) {
      console.error("Failed to fetch PDL data:", error);
    }
  }, [pdl_id]);

  const getFormatedDate = (date) => {
    return new Date(date)
      .toLocaleString("fr-FR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
      .replace(",", "");
  };

  useEffect(() => {
    onGetDevicesbyPdlId();
  }, [onGetDevicesbyPdlId]);

  return (
    <Card>
      <CardHeader
        sx={{
          backgroundColor: "#40bcc4",
          color: theme.palette.primary.main,
          height: "10px",
        }}
        title={"Listes des devices (" + data["nbdevices"] + ")"}
        titleTypographyProps={{ variant: "h6", fontSize: "0.8rem" }}
      />
      <CardContent>
        <Card
          sx={{
            backgroundColor: "#e0f4f4",
            marginBottom: 2,
          }}
        >
          <CardContent>
            <Typography
              gutterBottom
              variant="h7"
              component="div"
              color={theme.palette.primary.main}
              sx={{ fontWeight: "bold" }}
            >
              Vision globale
            </Typography>
            <Box display="flex" justifyContent="space-between">
              <Typography
                gutterBottom
                variant="h7"
                component="div"
                color={theme.palette.primary.main}
                sx={{ fontWeight: "bold", fontSize: "small" }}
              >
                Statut des appareil(s)
              </Typography>
              <Typography
                gutterBottom
                variant="h7"
                component="div"
                color={theme.palette.primary.main}
                sx={{ fontSize: "small" }}
              >
                {data["device_status_summary"]}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography
                gutterBottom
                variant="h7"
                component="div"
                color={theme.palette.primary.main}
                sx={{ fontWeight: "bold", fontSize: "small" }}
              >
                Plan de chauffe
              </Typography>
              <Typography
                gutterBottom
                variant="h7"
                component="div"
                color={theme.palette.primary.main}
                sx={{ fontSize: "small" }}
              ></Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography
                gutterBottom
                variant="h7"
                component="div"
                color={theme.palette.primary.main}
                sx={{ fontWeight: "bold", fontSize: "small" }}
              >
                Plan d'effacement
              </Typography>
              <Typography
                gutterBottom
                variant="h7"
                component="div"
                color={theme.palette.primary.main}
                sx={{ fontSize: "small" }}
              ></Typography>
            </Box>
          </CardContent>
        </Card>
        {devices.map((device, index) => (
          <React.Fragment key={index}>
            <Typography
              gutterBottom
              variant="h7"
              component="div"
              color={theme.palette.primary.main}
              sx={{ fontWeight: "bold", fontSize: "20px" }}
            >
              {device.name}
            </Typography>
            <Typography
              gutterBottom
              variant="h7"
              component="div"
              color={theme.palette.primary.main}
              sx={{ fontWeight: "bold" }}
            >
              {device.model}
            </Typography>
            <Card
              sx={{
                backgroundColor: "#f8f4f4",
                marginBottom: 2,
              }}
            >
              <CardContent>
                <Box display="flex" justifyContent="space-between">
                  <FormControl
                    variant="standard"
                    sx={{ marginRight: 2, flex: 1 }}
                  >
                    <InputLabel htmlFor={`active-${index}`} shrink>
                      Active
                    </InputLabel>
                    <Box
                      id={`active-${index}`}
                      display="flex"
                      alignItems="center"
                      sx={{ mt: 2 }}
                    >
                      <PowerSettingsNewIcon
                        style={{ color: device.active ? "green" : "red" }}
                      />
                    </Box>
                  </FormControl>
                  <FormControl
                    variant="standard"
                    sx={{ marginRight: 2, flex: 1 }}
                  >
                    <InputLabel htmlFor={`status-${index}`} shrink>
                      Statut
                    </InputLabel>
                    <Box
                      id={`status-${index}`}
                      display="flex"
                      alignItems="center"
                      sx={{ mt: 2 }}
                    >
                      <CircleIcon
                        style={{
                          color: device.statut ? "green" : "red",
                          fontSize: "16px",
                        }}
                      />
                    </Box>{" "}
                  </FormControl>
                  <FormControl
                    variant="standard"
                    sx={{ marginRight: 2, flex: 1 }}
                  >
                    <InputLabel htmlFor={`heating-plan-${index}`} shrink>
                      P. Chauffe
                    </InputLabel>
                    <Box
                      id={`heating-plan-${index}`}
                      display="flex"
                      alignItems="center"
                      sx={{ mt: 2 }}
                    >
                      {device.include_in_heating_plan ? (
                        <PlayCircleIcon style={{ color: "green" }} />
                      ) : (
                        <StopCircleIcon style={{ color: "grey" }} />
                      )}
                    </Box>
                  </FormControl>
                  <FormControl
                    variant="standard"
                    sx={{ marginRight: 2, flex: 1 }}
                  >
                    <InputLabel htmlFor={`curtailment-plan-${index}`} shrink>
                      P. Effacement
                    </InputLabel>
                    <Box
                      id={`curtailment-plan-${index}`}
                      display="flex"
                      alignItems="center"
                      sx={{ mt: 2 }}
                    >
                      {device.include_in_curtailment ? (
                        <PlayCircleIcon style={{ color: "green" }} />
                      ) : (
                        <StopCircleIcon style={{ color: "grey" }} />
                      )}
                    </Box>
                  </FormControl>
                </Box>
                <Box sx={{ mt: 5 }} />
                <Box display="flex" justifyContent="space-between">
                  <InputLabel htmlFor={`last-status-change-${index}`} shrink>
                    Dernier changement d'état
                  </InputLabel>
                  <Typography
                    gutterBottom
                    variant="h7"
                    component="div"
                    color={theme.palette.primary.main}
                    sx={{ fontSize: "small" }}
                  >
                    {getFormatedDate(device["last_status_change_date"])}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </React.Fragment>
        ))}
      </CardContent>
    </Card>
  );
};

export default AssociatedDevices;
