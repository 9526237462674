import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { updateActivePdl } from "../../../services/pdlService";
import { updateActiveDevice } from "../../../services/deviceService";
import { updateActiveCustomer } from "../../../services/customerService";

const ITEM_HEIGHT = 48;

const CellActionsRenderer = (props) => {
  const navigate = useNavigate();
  const { options, url, onUpdateRowData } = props;
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option) => {
    handleClose();
    if (option === "Consulter le détail") {
      navigate(`/${url}/${props.data?.id}`);
    }
  };

  const toggleActiveStatus = async (id, newStatut) => {
    let result;
    if (url === "detailpdl") {
      result = await updateActivePdl(id, newStatut);
    } else if (url === "detailDevice") {
      result = await updateActiveDevice(id, newStatut);
    } else if (url === "detailCustomer") {
      result = await updateActiveCustomer(id, newStatut);
    }

    if (result) {
      onUpdateRowData(id, newStatut);
    }
  };

  const UpdateStatut = () => {
    if (!props.data) return;

    const newStatut = props.data.active === true ? false : true;
    toggleActiveStatus(props.data.id, newStatut);
    handleClose();
  };

  return (
    <div>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          style={{ color: theme.palette.primary.main }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
            },
          }}
        >
          <MenuItem
            key={props.data?.active ? "Désactiver" : "Activer"}
            onClick={UpdateStatut}
          >
            {props.data?.active ? "Désactiver" : "Activer"}
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option} onClick={() => handleMenuItemClick(option)}>
              {option}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </div>
  );
};

export default CellActionsRenderer;
