import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  FormControl,
  CardHeader,
  Input,
  InputLabel,
  useTheme,
  FormControlLabel,
} from "@mui/material";
import IOSSwitch from "../../layout/IOSSwitch";
const Effacement = ({ data }) => {
  const theme = useTheme();
  const getValueOrDefault = (value) => {
    return value !== undefined && value !== null ? value : "";
  };

  return (
    <Card>
      <CardHeader
        sx={{
          backgroundColor: "#40bcc4",
          color: theme.palette.primary.main,
          height: "10px",
        }}
        title="Informations d'effacement"
        titleTypographyProps={{ variant: "h6", fontSize: "0.8rem" }}
      />
      <CardContent>
        <FormControl variant="standard" fullWidth sx={{ mb: 3 }}>
          <InputLabel htmlFor="provider" shrink>
            Puissance Max. théorique (kW)
          </InputLabel>
          <Input
            id="provider"
            value={getValueOrDefault(data?.contractual_state)}
            readOnly
          />
        </FormControl>

        <FormControl variant="standard" fullWidth sx={{ mb: 3 }}>
          <InputLabel htmlFor="credentials" shrink>
            Puissance Max. réelle (kW)
          </InputLabel>
          <Input
            id="credentials"
            value={getValueOrDefault(data?.contractual_state)}
            readOnly
          />
        </FormControl>

        <FormControl variant="standard" fullWidth sx={{ mb: 3 }}>
          <InputLabel htmlFor="token" shrink>
            Temps d'arrêt max (H)
          </InputLabel>
          <Input
            id="token"
            value={getValueOrDefault(data?.contractual_state)}
            readOnly
          />
        </FormControl>
      </CardContent>
    </Card>
  );
};

export default Effacement;
