function formatToPdlNumber(input) {
  const isValid = /^\d{14}$/.test(input);
  if (!isValid) {
    return input;
  }
  const formattedNumber = `${input.slice(0, 2)} ${input.slice(
    2,
    5
  )} ${input.slice(5, 8)} ${input.slice(8, 11)} ${input.slice(11)}`;
  return formattedNumber;
}

export { formatToPdlNumber };
