import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  FormControl,
  CardHeader,
  Input,
  InputLabel,
  useTheme,
  FormControlLabel,
} from "@mui/material";
import IOSSwitch from "../../layout/IOSSwitch";
const ProviderInfos = ({ data }) => {
  const theme = useTheme();
  const getValueOrDefault = (value) => {
    return value !== undefined && value !== null ? value : "";
  };

  return (
    <Card>
      <CardHeader
        sx={{
          backgroundColor: "#b8c494",
          color: theme.palette.primary.main,
          height: "10px",
        }}
        title="Informations du provider"
        titleTypographyProps={{ variant: "h6", fontSize: "0.8rem" }}
      />
      <CardContent>
        <Typography
          gutterBottom
          variant="h7"
          component="div"
          color={theme.palette.primary.main}
          sx={{ fontWeight: "bold" }}
        >
          Données en provenance du provider
        </Typography>
        <FormControl variant="standard" fullWidth sx={{ mb: 3 }}>
          <InputLabel htmlFor="provider" shrink>
            Provider
          </InputLabel>
          <Input
            id="provider"
            value={getValueOrDefault(data?.contractual_state)}
            readOnly
          />
        </FormControl>

        <FormControl variant="standard" fullWidth sx={{ mb: 3 }}>
          <InputLabel htmlFor="credentials" shrink>
            Credentials
          </InputLabel>
          <Input
            id="credentials"
            value={getValueOrDefault(data?.contractual_state)}
            readOnly
          />
        </FormControl>

        <FormControl variant="standard" fullWidth sx={{ mb: 3 }}>
          <InputLabel htmlFor="token" shrink>
            Token
          </InputLabel>
          <Input
            id="token"
            value={getValueOrDefault(data?.contractual_state)}
            readOnly
          />
        </FormControl>

        <FormControl variant="standard" sx={{ marginRight: 2, flex: 1 }}>
          <InputLabel htmlFor="provider-customer-status" shrink>
            Activation du Provider_customer
          </InputLabel>
          <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
            <FormControlLabel
              control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
              label=""
            />
          </Box>
        </FormControl>
      </CardContent>
    </Card>
  );
};

export default ProviderInfos;
