import React, { useState } from "react";
import IndeterminateCheckBoxSharpIcon from "@mui/icons-material/IndeterminateCheckBoxSharp";

const CertificationButtonRenderer = (props) => {
  const [status, setStatus] = useState(props.value);

  const toggleStatus = () => {
    const newStatus = !status;
    setStatus(newStatus);
    props.setValue(newStatus);
  };

  return (
    <span
      className="missionSpan"
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        height: "100%",
      }}
    >
      {/* à mettre décommenter si on veut le mettre en mode éditable */}
      {/* <span className="missionSpan" onClick={toggleStatus}> */}
      {status ? (
        <IndeterminateCheckBoxSharpIcon style={{ color: "green" }} />
      ) : (
        <IndeterminateCheckBoxSharpIcon style={{ color: "red" }} />
      )}
    </span>
  );
};

export default CertificationButtonRenderer;
