import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  Card,
  CardContent,
  CircularProgress,
  Typography,
  Button,
  Box,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import SearchIcon from "@mui/icons-material/Search";
import UploadAlert from "../../layout/UploadAlert";
import { useTheme } from "@mui/material/styles";

const AgGrid = ({
  gridRef,
  rowData,
  columnDefs,
  title,
  onRowClicked,
  search,
  onCellValueChange,
  filterColumns,
}) => {
  const theme = useTheme();
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [filteredRowCount, setFilteredRowCount] = useState(rowData.length);

  useEffect(() => {
    if (gridRef.current) {
      console.log("Grid is ready");
      setFilteredRowCount(rowData.length);
    }
  }, [rowData]);

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    gridRef.current.api.onFilterChanged();
  };

  // Function to handle filter changes
  const handleFilterChanged = () => {
    const filteredRowCount = gridRef.current.api.getDisplayedRowCount(); // Get the number of filtered rows
    setFilteredRowCount(filteredRowCount);
  };

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    setOpen(true);
    handleClick();
  };

  const handleClick = () => {
    setOpen(true);
  };

  const isExternalFilterPresent = () => {
    return searchText !== "";
  };

  const doesExternalFilterPass = (node) => {
    const search = searchText.toLowerCase();
    return filterColumns.some((col) => {
      const cellValue = node.data[col];
      const cellValueStr = cellValue ? cellValue.toString().toLowerCase() : "";
      return cellValueStr.includes(search);
    });
  };

  const onCellValueChanged = (params) => {
    console.log("Cell value changed:", params);
    if (onCellValueChange) {
      onCellValueChange(params);
    }
  };

  return (
    <Card
      sx={{
        backgroundColor: "#f5f5f5",
        margin: "20px",
        padding: "20px",
        height: "800px",
        boxShadow: "lg",
      }}
    >
      <CardContent style={{ height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            mb: 2,
          }}
        >
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            color={theme.palette.primary.main}
          >
            Liste des {title} ({filteredRowCount}){" "}
            {/* Display filteredRowCount */}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <TextField
              variant="standard"
              placeholder={search}
              value={searchText}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => gridRef.current.api.onFilterChanged()}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<DownloadIcon />}
              onClick={onExportClick}
            >
              Exporter le fichier .csv
            </Button>
          </Box>
        </Box>

        <Card
          sx={{
            backgroundColor: "#ffffff",
            margin: "20px",
            padding: "20px",
            height: "640px",
            boxShadow: "lg",
          }}
        >
          {!rowData || rowData.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "640px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <CardContent style={{ height: "100%" }}>
              <div className="ag-theme-alpine" style={{ height: "100%" }}>
                <AgGridReact
                  ref={gridRef}
                  columnDefs={columnDefs}
                  rowData={rowData}
                  onRowClicked={onRowClicked}
                  isExternalFilterPresent={isExternalFilterPresent}
                  doesExternalFilterPass={doesExternalFilterPass}
                  onFilterChanged={handleFilterChanged}
                  onCellValueChanged={onCellValueChanged}
                />
              </div>
            </CardContent>
          )}
        </Card>
        <UploadAlert open={open} setOpen={setOpen} />
      </CardContent>
    </Card>
  );
};

export default AgGrid;
